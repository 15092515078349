import React, { useEffect, useMemo, useState} from 'react';
import {Col, Row, Container, Button} from "react-bootstrap";
import Select from 'react-select';
import DataService from "../../services/DataService";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import BerichtGame from "../../component/BerichtGame";
import Form from "react-bootstrap/Form";
import {evaluateEvents} from "../../util/Utils";
import TeamInfo from "../../component/TeamInfo";
import Table from "react-bootstrap/Table";
import Team from "../../component/Team";
import NewMemberModal from "../../component/NewMemberModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import {user as userAtom} from "../../atoms/user";
import NewStaffModal from "../../component/NewStaffModal";
import NewSPPModal from "../../component/NewSSPModal";
import {emptySpielBerichtDataSave, emptyTeam, emptyWetter} from "../../util/EmptyObjects";
import FireMemberModal from "../../component/FireMemberModal";

const mapToDropDownTeam = (data: Team) => {
    return {label: data.name, value: data.id};
};

const mapToDropDownPaarung = (data: SpieltagPaarung) : {label: string, value: any} => {
    return {label: data.heim.name+" vs. "+data.gast.name, value: data};
};

const mapToDropDownWetter = (data: Wetter) => {
    return {label: data.name, value: data.id};
};

const mapToPaarung = (data: SpielBerichtDataSave): SpieltagPaarung => {
    return {
        heim: {
            id: data.heim.id,
            name: data.heim.name,
            trainer: "",
            touchdown: null
        },
        gast: {
            id: data.gast.id,
            name: data.gast.name,
            trainer: "",
            touchdown: null
        }
    };
};

const mapToStaff = (hired: SpielBerichtDataStaffHired):Staff => {
    let name:string="";
    switch(hired.type) {
        case "CHEERLEADER":
            name = "Cheerleader";
            break;
        case "DOC":
            name = "Sanitäter";
            break;
        case "REROLL":
            name = "Wiederholungswürfe";
            break;
        case "TRAINER_ASSISTANT":
            name = "Trainerassistent";
            break;
    }
    return {
        amount: hired.amount,
        kosten: hired.kosten,
        name: name,
        teamId: hired.teamId,
        type: hired.type
    }
};

export default function Bericht() {
    const navigate = useNavigate();

    const service = useMemo(() => DataService.getInstance(), []);
    const [user, setUser] = useRecoilState(userAtom);
    const [loading, setLoading] = useState<boolean>(false);
    const [wetterList, setWetterList] = useState<Wetter[]>([]);
    const [paarungen, setPaarungen] = useState<SpieltagPaarung[]>([]);
    const [activeInput, setActiveInput] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalStaff, setShowModalStaff] = useState<boolean>(false);
    const [showModalSsp, setShowModalSsp] = useState<boolean>(false);
    const [showModalFired, setShowModalFired] = useState<boolean>(false);
    const [data, setData] = useState<SpielBerichtDataSave>(emptySpielBerichtDataSave);
    // Berichtsdaten
    const [selectedSpieltag, setSelectedSpieltag] = useState<number>(-1);
    const [selectedPaarung, setSelectedPaarung] = useState<SpieltagPaarung | null>(null);
    const [newMember, setNewMember] = useState<Player[]>([]);
    //Before
    const [wetter, setWetter] = useState<Wetter>(emptyWetter);
    const [anstoss, setAnstoss] = useState<Team>(emptyTeam);
    //InGame
    const [gameEvents, setGameEvents] = useState<GameEvent[]>([]);
    const [touchdownsHeim, setTouchdownsHeim] = useState<number>(0);
    const [touchdownsGast, setTouchdownsGast] = useState<number>(0);
    //After
    const [allSspPlayer, setAllSspPlayer] = useState<SspPlayer[]>([]);
    const [gameSspPlayer, setGameSspPlayer] = useState<SspPlayer[]>([]);
    const [hiredPlayer, setHiredPlayer] = useState<SpielBerichtDataPlayerHired[]>([]);
    const [hiredStaff, setHiredStaff] = useState<Staff[]>([]);
    const [firedPlayer, setFiredPlayer] = useState<BasePlayer[]>([]);
//
    const evaluate = (events: GameEvent[]): void => {
        if(selectedPaarung != null) {
            let evalSspPlayer = evaluateEvents(events,
                selectedPaarung.heim.id, (t) => {data.heim.punkte = t; setTouchdownsHeim(t)},
                selectedPaarung.gast.id, (t) => {data.gast.punkte = t; setTouchdownsGast(t)});
            setGameSspPlayer(evalSspPlayer);
            const map = new Map<number, SspPlayer>();
            const arr3 = [...data.playerSspList, ...evalSspPlayer];
            for(const obj of arr3) {
                if(!map.has(obj.id)) {
                    // add
                    map.set(obj.id, obj);
                } else {
                    // update
                    // @ts-ignore
                    map.set(obj.id, {...map.get(obj.id),id: obj.id,name: obj.name,ssp: map.get(obj.id)?.ssp + obj.ssp });
                }
            }
            setAllSspPlayer([...map.values()]);
        }
    }
    // @ts-ignore
    const params: { saison: number } = useParams();
    const [queryParams] = useSearchParams();

    useEffect(() => {
        if(queryParams.get('edit') === "true" && selectedPaarung == null) {
            // @ts-ignore
            const id = parseInt(queryParams.get('id'));
            service.getSpielberichtListe(params.saison, user.token, setUser, list => {
                const bericht = list.find(l => l.id === id);
                if (bericht !== undefined) {
                    setData(bericht.data);
                    setSelectedSpieltag(bericht.spieltag);
                    setSelectedPaarung(mapToPaarung(bericht.data));
                    // setAllSspPlayer(bericht.data.playerSspList);
                    setGameSspPlayer(evaluateEvents(bericht.data.events, bericht.data.heim.id, (t) => t, bericht.data.gast.id, (t) => t));
                    setAnstoss(bericht.data.kickoff);
                    setWetter(bericht.data.wetter);
                    setGameEvents(bericht.data.events);
                    setHiredPlayer(bericht.data.hired);
                    setFiredPlayer(bericht.data.firedPlayer);
                    setHiredStaff(bericht.data.hiredStaff.map(mapToStaff));
                    setTouchdownsHeim(bericht.data.heim.punkte);
                    setTouchdownsGast(bericht.data.gast.punkte);
                    setActiveInput(true);
                    service.staticData().getWetter(params.saison, selectedSpieltag, setWetterList, (b) => b);
                    service.team().getNewMember(params.saison, bericht.data.heim.id, p => setNewMember(old => old.concat(p)), b => b);
                    service.team().getNewMember(params.saison, bericht.data.gast.id, p => setNewMember(old => old.concat(p)), b => b);
                }
            }, (b) => b);
        } else {
            if(queryParams.get('edit') !== "true" && selectedSpieltag !== -1) {
                service.spieltag().getPaarungen(params.saison, selectedSpieltag, setPaarungen, (b) => b);
            }
            if(queryParams.get('edit') !== "true" && selectedSpieltag !== -1 && selectedPaarung != null) {
                service.staticData().getWetter(params.saison, selectedSpieltag, setWetterList, (b) => b);
                service.team().getNewMember(params.saison, selectedPaarung.heim.id, p => setNewMember(old => old.concat(p)), b => b);
                service.team().getNewMember(params.saison, selectedPaarung.gast.id, p => setNewMember(old => old.concat(p)), b => b);
                service.createSpielBericht(params.saison, selectedSpieltag, selectedPaarung.heim, selectedPaarung.gast, (d) => {
                    setData(d);
                    setAllSspPlayer(d.playerSspList);
                    }, (b) => {
                    setActiveInput(true);
                });
            }
        }
    }, [service, selectedSpieltag, selectedPaarung, params.saison, queryParams, setUser, user.token])

    return (
        <div className="Bericht, lightGreyBg">
            {
                queryParams.get('edit') === "true" ?
                    <Row>
                        <Col>
                            <h1>Spielbericht</h1> für Spieltag: {selectedSpieltag}
                        </Col>
                    </Row>
                    :
                    <>
                        <Row>
                            <Col>
                                <h1>Spielbericht</h1> für Spieltag:
                                <Form.Select
                                    value={selectedSpieltag}
                                    onChange={event => setSelectedSpieltag(Number(event.target.value))}>
                                    <option value={-1}>Spieltag ?</option>
                                    {
                                        Array.from({length: 12}, (_, i) => i + 1).map(i => <option key={i} value={i}>Spieltag {i}</option>)
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Select
                                    options={paarungen.map(mapToDropDownPaarung)}
                                    placeholder={'Wähle die Paarung'}
                                    isDisabled={selectedSpieltag === -1}
                                    onChange={(v) => {
                                        if(v!=null) {
                                            // @ts-ignore
                                            setSelectedPaarung(v.value);
                                        }
                                    }}
                                ></Select>
                            </Col>
                        </Row>
                    </>
            }
            {
                activeInput &&
                <>
                    <Row>
                        <Col><span className={"bold"}>Punkte:</span> {touchdownsHeim}</Col>
                        <Col>&nbsp;</Col>
                        <Col><span className={"bold"}>Punkte:</span> {touchdownsGast}</Col>
                    </Row>
                    <Row>
                        <Col>&nbsp;</Col>
                    </Row>
                    <Row>
                        <Col>
                            <span className={"bold"}>Wetter:</span>
                            <Select
                                options={wetterList.map(mapToDropDownWetter)}
                                placeholder={'Wähle das Wetter'}
                                defaultValue={queryParams.get('edit') === "true" ? {value: wetter.id, label: wetter.name} : undefined}
                                onChange={selectedOption => {
                                    if(selectedOption != null) {
                                        setWetter({id: selectedOption.value, name: selectedOption.label, description: ''});
                                    }
                                }}
                            ></Select>
                        </Col>
                        <Col>
                            <span className={"bold"}>Anstoßendes Team:</span>
                            <Select
                                options={[data.heim, data.gast].map(mapToDropDownTeam)}
                                placeholder={'Wähle das Anstoßende Team'}
                                defaultValue={queryParams.get('edit') === "true" ? {value: anstoss.id, label: anstoss.name} : undefined}
                                onChange={selectedOption => {
                                    if(selectedOption != null) {
                                        setAnstoss({id: selectedOption.value, name: selectedOption.label});
                                    }
                                }}
                            ></Select>
                        </Col>
                        <Col>
                            <>
                                <span className={"bold"}>Au&szlig;enseiter:</span> {data.aussenseiter.name}
                            </>
                        </Col>
                    </Row>
                    <Row>
                        <Col>&nbsp;</Col>
                    </Row>
                    <Row>
                        <Col>
                            <TeamInfo
                                edit={true}
                                data={data.heim}
                                setFans={(fans) => {
                                    data.heim.fans = fans;
                                    setData(data);
                                }}
                                setNuffle={(nuffle) => {
                                    data.heim.nuffle = nuffle;
                                    setData(data);
                                }}
                                setAnreize={(anreize) => {
                                    data.heim.anreize = anreize;
                                    setData(data);
                                }}
                                setEinnahmen={(einnahmen) => {
                                    data.heim.einnahmen = einnahmen;
                                    setData(data);
                                }}
                                setBewertung={(b) => {
                                    data.heim.bewertung = b;
                                    setData(data);
                                }}
                            />
                        </Col>
                        <Col>
                            <TeamInfo
                                edit={true}
                                data={data.gast}
                                setFans={(fans) => {
                                    data.gast.fans = fans;
                                    setData(data);
                                }}
                                setNuffle={(nuffle) => {
                                    data.gast.nuffle = nuffle;
                                    setData(data);
                                }}
                                setAnreize={(anreize) => {
                                    data.gast.anreize = anreize;
                                    setData(data);
                                }}
                                setEinnahmen={(einnahmen) => {
                                    data.gast.einnahmen = einnahmen;
                                    setData(data);
                                }}
                                setBewertung={(b) => {
                                    data.gast.bewertung = b;
                                    setData(data);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>&nbsp;</Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3>Aktion wären des Spiel</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <BerichtGame saison={params.saison}
                                         spieltag={selectedSpieltag}
                                         selbst={data.heim}
                                         gegner={data.gast}
                                         events={gameEvents}
                                         addEvent={(event) => {
                                             const events = [...gameEvents, event]
                                             evaluate(events);
                                             setGameEvents(events);
                                         }}
                                         removeEvent={index => {
                                             const events = [...gameEvents];
                                             events.splice(index, 1);
                                             evaluate(events);
                                             setGameEvents(events);
                                         }}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Container>
                                <Table striped className={"caption-top"}>
                                    <caption>Verdiente SSP</caption>
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>SSP</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        gameSspPlayer.map((player) =>
                                            <tr key={player.id}>
                                                <td>
                                                    { player.teamId && <Team team={{id: player.teamId, name: ''}} small={true} onlyLogo={true} /> }
                                                    {player.name}
                                                </td>
                                                <td>{player.ssp}</td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </Table>
                            </Container>
                        </Col>
                        <Col>
                            <NewMemberModal show={showModal} member={newMember} handleClose={() => setShowModal(false)} handleAdd={p => {
                                // @ts-ignore
                                setHiredPlayer(old => {old.push({id:p.id, teamId:p.teamId, name:'', typ:p.position, kosten:p.kosten}); return old;})
                                setShowModal(false);
                            }} />
                            <Container>
                                <Button variant="primary" size="sm" onClick={(e) => {
                                    setShowModal(true);
                                }}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                                <Table striped className={"caption-top"}>
                                    <caption>Neu Angeworbene Spieler</caption>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Typ</th>
                                        <th>Kosten</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        hiredPlayer.map((player, index) =>
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td><Form.Control type="text" defaultValue={player.name} onChange={event => player.name = event.target.value} /></td>
                                                <td>
                                                    {player.teamId && <Team team={{id: player.teamId, name: ''}} small={true} onlyLogo={true}/>}
                                                    {player.typ}
                                                </td>
                                                <td>{player.kosten}</td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </Table>
                            </Container>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Container>
                                <NewSPPModal show={showModalSsp}
                                             player={allSspPlayer}
                                             handleClose={() => setShowModalSsp(false)}
                                             handleAdd={p => {
                                                 p.visible = true;
                                                 setShowModalSsp(false);
                                             }}
                                />
                                <Button variant="primary" size="sm" onClick={(e) => {
                                    setShowModalSsp(true);
                                }}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                                <Table striped className={"caption-top"}>
                                    <caption>Gekaufte Verbesserungen</caption>
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>SSP</th>
                                        <th>Ausgegeben</th>
                                        <th>Verbesserung</th>
                                        <th>Wertsteigerung</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        allSspPlayer.filter(p => p.visible === true).map((player) =>
                                            <tr key={player.id}>
                                                <td>
                                                    { player.teamId && <Team team={{id: player.teamId, name: ''}} small={true} onlyLogo={true} /> }
                                                    {player.name}
                                                </td>
                                                <td>{player.ssp}</td>
                                                <td><Form.Control type="number" defaultValue={player?.sspUsed} onChange={event => player.sspUsed = parseInt(event.target.value)}/></td>
                                                <td><Form.Control type="text" defaultValue={player?.verbesserung} onChange={event => player.verbesserung = event.target.value} /></td>
                                                <td><Form.Control type="number" defaultValue={player?.wertsteigerung} onChange={event => player.wertsteigerung = parseInt(event.target.value)} /></td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </Table>
                            </Container>
                        </Col>
                        <Col>
                            <NewStaffModal
                                show={showModalStaff}
                                heimId={data.heim.id}
                                heimReroll={data.heim.rerollCost}
                                gastId={data.gast.id}
                                gastReroll={data.gast.rerollCost}
                                handleClose={() => setShowModalStaff(false)}
                                handleAdd={s => {
                                // @ts-ignore
                                setHiredStaff(old => {
                                    let st = old.find(o => o.teamId===s.teamId && o.type===s.type);
                                    if(st === undefined) {
                                        old.push(s);
                                    } else {
                                        st.amount += s.amount;
                                        st.kosten += s.kosten;
                                    }
                                    return old;
                                })
                                setShowModalStaff(false);
                            }} />
                            <Container>
                                <Button variant="primary" size="sm" onClick={(e) => {
                                    setShowModalStaff(true);
                                }}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                                <Table striped className={"caption-top"}>
                                    <caption>Neu Mitarbeiter</caption>
                                    <thead>
                                    <tr>
                                        <th>Anzahl</th>
                                        <th>Team</th>
                                        <th>Typ</th>
                                        <th>Kosten</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        hiredStaff.map((staff, index) =>
                                            <tr key={index}>
                                                <td>{staff.amount}</td>
                                                <td><Team team={{id: staff.teamId, name: ''}} small={true} onlyLogo={true}/></td>
                                                <td>{staff.name}</td>
                                                <td>{staff.kosten}</td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </Table>
                            </Container>
                        </Col>
                    </Row>
                    <Row>
                        <Col>&nbsp;</Col>
                        <Col>
                            <FireMemberModal
                                show={showModalFired}
                                saison={params.saison}
                                spieltag={selectedSpieltag}
                                heimId={data.heim.id}
                                gastId={data.gast.id}
                                handleClose={() => setShowModalFired(false)}
                                handleAdd={s => {
                                    // @ts-ignore
                                    setFiredPlayer(old => {
                                        old.push(s);
                                        return old;
                                    })
                                    setShowModalFired(false);
                                }} />
                            <Container>
                                <Button variant="primary" size="sm" onClick={(e) => {
                                    setShowModalFired(true);
                                }}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                                <Table striped className={"caption-top"}>
                                    <caption>Gefeuerte Spieler</caption>
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Team</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        firedPlayer.map((player, index) =>
                                            <tr key={index}>
                                                <td>{player.name}</td>
                                                <td><Team team={{id: player.teamId!, name: ''}} small={true} onlyLogo={true}/></td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </Table>
                            </Container>
                        </Col>
                    </Row>
                    <Row>
                        <Col>&nbsp;</Col>
                    </Row>
                    <Row>
                        {
                            queryParams.get('edit') === "true" ?
                                <Button onClick={ () => {
                                    service.updateSpielbericht(params.saison, {
                                        spieltag: selectedSpieltag,
                                        aussenseiter: data.aussenseiter,
                                        gast: data.gast,
                                        heim: data.heim,
                                        kickoff: anstoss,
                                        wetter: wetter,
                                        events: gameEvents,
                                        playerSspList: allSspPlayer.map(p => {p.ssp -= p.sspUsed === undefined ? 0 : p.sspUsed; return p;}),
                                        hired: hiredPlayer,
                                        hiredStaff: hiredStaff,
                                        firedPlayer: firedPlayer
                                    }, (b:boolean) => {
                                        navigate("/saison/" + params.saison + "/member/liste");
                                    });
                                }}>Ändern</Button>
                            :
                                <Button onClick={ () => {
                                    if(selectedPaarung != null || !loading) {
                                        setLoading(true);
                                        service.saveSpielbericht(params.saison, {
                                            spieltag: selectedSpieltag,
                                            aussenseiter: data.aussenseiter,
                                            gast: data.gast,
                                            heim: data.heim,
                                            kickoff: anstoss,
                                            wetter: wetter,
                                            events: gameEvents,
                                            playerSspList: allSspPlayer.map(p => {p.ssp -= p.sspUsed === undefined ? 0 : p.sspUsed; return p;}),
                                            hired: hiredPlayer,
                                            hiredStaff: hiredStaff,
                                            firedPlayer: firedPlayer
                                        }, (b:boolean) => {
                                            setLoading(b);
                                            setActiveInput(false);
                                            setData(emptySpielBerichtDataSave)
                                            setSelectedSpieltag(-1);
                                            setSelectedPaarung(null);
                                            setAllSspPlayer([]);
                                            setHiredPlayer([]);
                                            setFiredPlayer([]);
                                        });
                                    }
                                }}>Eintragen</Button>
                        }
                    </Row>
                </>
            }
        </div>
    );
}